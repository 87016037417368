<template>
  <div id="cameraControls" v-show="visibleList.dialogVisible">
    <slot name="video"></slot>
    <div v-show="visibleList.newestFaceListVisible" class="right_list">
      <img
        style="
          top: 0;
          left: 0;
          right: 0;
          margin: auto;
          cursor: pointer;
          transform: translateY(-50%) rotateZ(90deg);
        "
        class="icon_arrow"
        @click="scroll('up')"
        src="../../assets/monitor/jt.png"
        alt=""
      />
      <div class="listScreen">
        <div class="listContent" style="transition: transform 0.4s">
          <img
            v-for="face in newestFaceList"
            :src="face.faceUrl"
            :key="face.id"
            style="margin: 10px 0; cursor: pointer; width: 106px; height: 90px"
            @click="newestTargetTrail(face.faceDataId)"
          />
        </div>
      </div>
      <img
        style="
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          cursor: pointer;
          transform: translateY(50%) rotateZ(-90deg);
        "
        class="icon_arrow"
        @click="scroll('down')"
        src="../../assets/monitor/jt.png"
        alt=""
      />
    </div>
    <div
      v-show="visibleList.leftCheckboxVisible"
      class="left_list"
      ref="leftCheckboxVisible"
    >
      <div class="layout" style="padding: 20px 15px 10px 15px">
        <div
          style="
            width: 420px;
            margin-bottom: 15px;
            border-bottom: 1px solid #f0f0f0;
          "
        >
          <!--                    <div class="checkButton">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="switchVisible('playVideo')"
                        >
                            {{ player ? '暂停' : '播放' }}
                        </button>
                    </div>-->
          <!--                    <div class="checkButton">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="switchVisible('historyVideo')"
                                            >
                                                {{option.currentVideo ? '历史录像' : '实时录像'}}
                                            </button>
                                        </div>
                                        <div class="checkButton">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="scale('close')"
                                            >
                                                拉近
                                            </button>
                                        </div>
                                        <div class="checkButton">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="scale('far')"
                                            >
                                                拉远
                                            </button>
                                        </div>-->
          <!--                    <div class="checkButton">
                                            <button
                                                type="button"
                                                class="btn btn-primary"
                                                @click="switchVisible('hiddenIcon')"
                                            >
                                                {{ option.showView ? '隐藏窗口标识' : '显示窗口标识' }}
                                            </button>
                                        </div>-->
          <div class="checkButton">
            <button
              type="button"
              class="btn btn-primary"
              @click="switchVisible('captureFace')"
            >
              {{ option.showFaceImg ? "隐藏人脸抓拍图像" : "显示人脸抓拍图像" }}
            </button>
          </div>
          <div class="checkButton">
            <button
              type="button"
              class="btn btn-primary"
              @click="switchVisible('captureAbnormal')"
            >
              {{
                option.showAbnormalImg ? "隐藏异常抓拍图像" : "显示异常抓拍图像"
              }}
            </button>
          </div>
          <div class="checkButton">
            <button
              @click="exitFullScreen('click')"
              type="button"
              class="btn btn-primary"
            >
              退出全屏
            </button>
          </div>
        </div>
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[1].enable"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
              @change="setDetectionConfig(1)"
            />
            <span>启用关注人检测</span>
          </label>
          <div v-show="detectionConfigOption[1].enable" class="checkBox">
            显示近
            <CSSelect iWidth="36px" height="40px">
              <select
                v-model="detectionConfigOption[1].val"
                @change="setDetectionConfig(1)"
                style="padding-left: 5px; width: 100px; height: 40px"
              >
                <option value="">请选择</option>
                <option v-for="time in timeList" :key="time" :value="time">
                  {{ time }}天
                </option>
              </select>
            </CSSelect>
            数据
          </div>
        </div>

        <!--                <div class="inputBox">
                    <label>
                        <input v-model="detectionConfigOption[2].enable" @change="setDetectionConfig(2)"
                               style="margin-right:15px;top:4px" type="checkbox"
                        />
                        <span>启用人流检测</span>
                    </label>
                    <div v-show="detectionConfigOption[2].enable" class="checkBox">显示近
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="detectionConfigOption[2].val" style="padding-left:5px;width:90px"
                                    @change="setDetectionConfig(2)"
                            >
                                <option value="">天</option>
                                <option
                                    v-for="time in timeList"
                                    :key="time"
                                    :value="time"
                                >
                                    {{ time }}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </div>
                </div>-->
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[3].enable"
              @change="setDetectionConfig(3)"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
            />
            <span>启用汽车违停检测</span>
          </label>
          <div v-show="detectionConfigOption[3].enable" class="checkBox">
            显示近
            <CSSelect iWidth="36px" height="40px">
              <select
                v-model="detectionConfigOption[3].val"
                @change="setDetectionConfig(3)"
                style="padding-left: 5px; width: 100px; height: 40px"
              >
                <option value="">请选择</option>
                <option v-for="time in timeList" :key="time" :value="time">
                  {{ time }}天
                </option>
              </select>
            </CSSelect>
            数据
          </div>
        </div>
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[4].enable"
              @change="setDetectionConfig(4)"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
            />
            <span>启用未戴口罩检测</span>
          </label>
          <div v-show="detectionConfigOption[4].enable" class="checkBox">
            显示近
            <CSSelect iWidth="36px" height="40px">
              <select
                v-model="detectionConfigOption[4].val"
                style="padding-left: 5px; width: 100px; height: 40px"
                @change="setDetectionConfig(4)"
              >
                <option value="">天</option>
                <option v-for="time in timeList" :key="time" :value="time">
                  {{ time }}天
                </option>
              </select>
            </CSSelect>
            数据
          </div>
        </div>
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[5].enable"
              @change="setDetectionConfig(5)"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
            />
            <span>启用吸烟检测</span>
          </label>
          <div v-show="detectionConfigOption[5].enable" class="checkBox">
            显示近
            <CSSelect iWidth="36px" height="40px">
              <select
                v-model="detectionConfigOption[5].val"
                style="padding-left: 5px; width: 100px; height: 40px"
                @change="setDetectionConfig(5)"
              >
                <option value="">天</option>
                <option v-for="time in timeList" :key="time" :value="time">
                  {{ time }}天
                </option>
              </select>
            </CSSelect>
            数据
          </div>
        </div>
        <!--                新加-->
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[6].enable"
              @change="setDetectionConfig(6)"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
            />
            <span>启用跌倒检测</span>
          </label>
          <div v-show="detectionConfigOption[6].enable" class="checkBox">
            显示近
            <CSSelect iWidth="36px" height="40px">
              <select
                v-model="detectionConfigOption[6].val"
                style="padding-left: 5px; width: 100px; height: 40px"
                @change="setDetectionConfig(6)"
              >
                <option value="">天</option>
                <option v-for="time in timeList" :key="time" :value="time">
                  {{ time }}天
                </option>
              </select>
            </CSSelect>
            数据
          </div>
        </div>
        <!--                <div class="inputBox">-->
        <!--                    <label>-->
        <!--                        <input v-model="option.checkFall" @change="clearTime('checkFall')"-->
        <!--                               style="margin-right:15px;top:4px" type="checkbox"/>-->
        <!--                        <span>启用跌倒检测</span>-->
        <!--                    </label>-->
        <!--                    <div v-show="option.checkFall" class="checkBox">显示近-->
        <!--                        <CSSelect iWidth="36px" height="40px">-->
        <!--                            <select v-model="option.checkFallTime" style="padding-left:5px;width:90px">-->
        <!--                                <option value="">天</option>-->
        <!--                                <option-->
        <!--                                    v-for="time in timeList"-->
        <!--                                    :key="time"-->
        <!--                                    :value="time"-->
        <!--                                >-->
        <!--                                    {{ time }}天-->
        <!--                                </option>-->
        <!--                            </select>-->
        <!--                        </CSSelect>-->
        <!--                        数据-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="inputBox">
                    <label>
                        <input v-model="option.checkBlock" @change="clearTime('checkBlock')"
                               style="margin-right:15px;top:4px" type="checkbox"/>
                        <span>启用监控遮挡检测</span>
                    </label>
                    <div v-show="option.checkBlock" class="checkBox">显示近
                        <CSSelect iWidth="36px" height="40px">
                            <select v-model="option.checkBlockTime" style="padding-left:5px;width:90px">
                                <option value="">天</option>
                                <option
                                    v-for="time in timeList"
                                    :key="time"
                                    :value="time"
                                >
                                    {{ time }}
                                </option>
                            </select>
                        </CSSelect>
                        数据
                    </div>
                </div>-->
        <div class="inputBox">
          <label>
            <input
              v-model="detectionConfigOption[8].enable"
              @change="setDetectionConfigGather(8)"
              style="margin-right: 15px; top: 4px"
              type="checkbox"
            />
            <span>启用人员聚集检测</span>
          </label>
          <div v-show="detectionConfigOption[8].enable" class="checkBox">
            <div class="blankBox">
              大于等于
              <input
                style="
                  width: 90px;
                  padding-left: 10px;
                  border-radius: 4px;
                  border: 1px solid #979797;
                "
                type="text"
                calss="cs-input"
                placeholder="限整数"
                v-model="detectionConfigOption[8].count"
                @change="setDetectionConfigGather(8)"
              />
              人时抓拍
            </div>
            <div class="blankBox">
              显示近
              <CSSelect iWidth="36px" height="40px">
                <select
                  v-model="detectionConfigOption[8].val"
                  @change="setDetectionConfigGather(8)"
                  style="width: 100px; height: 40px"
                >
                  <option value="">天</option>
                  <option v-for="time in timeList" :key="time" :value="time">
                    {{ time }}天
                  </option>
                </select>
              </CSSelect>
              数据
            </div>
          </div>
        </div>
        <!--                <div class="inputBox">-->
        <!--                    <label>-->
        <!--                        <input v-model="option.checkWarning" @change="clearTime('checkWarning')"-->
        <!--                               style="margin-right:15px;top:4px;" type="checkbox"/>-->
        <!--                        <span>启用区域入侵检测</span>-->
        <!--                    </label>-->
        <!--                    <div v-show="option.checkWarning" class="checkBox">-->
        <!--                        <div>-->
        <!--                            <button-->
        <!--                                type="button"-->
        <!--                                class="btn btn-primary"-->
        <!--                                style="width:100px;height:40px;margin-bottom:10px"-->
        <!--                                @click="WarningArea('edit')"-->
        <!--                            >-->
        <!--                                绘制-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div>-->
        <!--                            <button-->
        <!--                                type="button"-->
        <!--                                class="btn btn-primary"-->
        <!--                                style="width:100px;height:40px;margin-bottom:10px"-->
        <!--                                @click="WarningArea('save')"-->
        <!--                            >-->
        <!--                                保存绘制-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div>-->
        <!--                            <button-->
        <!--                                type="button"-->
        <!--                                class="btn btn-primary"-->
        <!--                                style="width:100px;height:40px;margin-bottom:10px"-->
        <!--                                @click="WarningArea('delete')"-->
        <!--                            >-->
        <!--                                删除绘制-->
        <!--                            </button>-->
        <!--                        </div>-->
        <!--                        <div>显示近-->
        <!--                            <CSSelect iWidth="36px" height="40px">-->
        <!--                                <select style="padding-left:5px;width:90px" v-model="option.warningDays">-->
        <!--                                    <option value="">天</option>-->
        <!--                                    <option-->
        <!--                                        v-for="time in timeList"-->
        <!--                                        :key="time.id"-->
        <!--                                        :value="time.name"-->
        <!--                                    >-->
        <!--                                        {{ time.name }}-->
        <!--                                    </option>-->
        <!--                                </select>-->
        <!--                            </CSSelect>-->
        <!--                            数据-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
      </div>
      <img
        class="put"
        @click="putCameraControls(isShowText)"
        :src="isShowText ? ssIcon : zkIcon"
        alt=""
      />
      <div
        v-show="visibleList.warningBoxVisible"
        @mousemove="captureCursor($event)"
        @mousedown="moveCaptureBox($event)"
        style="
          box-sizing: border-box;
          width: 400px;
          height: 300px;
          position: fixed;
          top: 200px;
          left: 500px;
          border: 3px solid rgb(26, 179, 148);
          cursor: grab;
          display: none;
        "
        class="canvas"
      ></div>
    </div>
    <!-- 显示、隐藏异常抓拍图像 -->
    <div
      v-show="visibleList.bottomAbnormalListVisible"
      class="bottom_list"
      ref="putListScreen"
    >
      <img
        style="top: 0; bottom: 0; left: -10px; margin: auto; cursor: pointer"
        @click="scroll('left')"
        class="icon_arrow"
        src="../../assets/monitor/jt.png"
        alt=""
      />
      <!-- 下方 -->
      <div
        class="listScreen listScreens"
        style="margin: 20px 30px; overflow: hidden"
      >
        <img
          class="bottoms imgd"
          @click="putListScreen(true)"
          v-if="iSput"
          src="../../assets/icon/sh.png"
          alt=""
        />
        <img
          class="bottoms imgs"
          @click="putListScreen(false)"
          v-else
          src="../../assets/icon/xl.png"
          alt=""
        />
        <div class="listContent" style="transition: transform 0.4s">
          <div
            class="block"
            v-for="(abnormal, index) in abnormalFaceList"
            :key="index"
          >
            <img
              style="margin: 0px 10px 10px"
              :src="snapImgHost + abnormal.phoneUrl"
              width="90px"
              height="90px"
            />
            <span
              class="abnormalSpanText"
              :style="
                abnormal.id === 12
                  ? 'color:#1ab394;background-color:#D8FFF8'
                  : 'color:#FF5A5A;background-color:#FFE6E6'
              "
            >
              {{ abnormalType[abnormal.type] }}
            </span>
          </div>
        </div>
      </div>
      <img
        src="../../assets/monitor/jt.png"
        @click="scroll('right')"
        class="icon_arrow"
        style="
          top: 0;
          bottom: 0;
          right: -10px;
          margin: auto;
          cursor: pointer;
          transform: rotateY(180deg);
        "
        alt=""
      />
    </div>
    <div v-show="visibleList.underRulerVisible" class="time_ruler">
      <div
        class="inBox"
        style="
          height: 60px;
          width: 1010px;
          overflow-x: auto;
          float: left;
          background-color: #fff;
          border-radius: 5px 0px 0px 5px;
        "
      >
        <div class="rulerBox" style="height: 40px; position: relative">
          <div @click="turnToTheTime($event)">
            <span
              v-for="scale in rulerScale"
              :key="scale.id"
              class="scaleText"
              :style="'---textLeft:' + scale.time * 50 + 'px'"
            >
              <span
                v-if="scale.time !== undefined"
                class="scaleTxt"
                style="position: relative; z-index: 1; cursor: pointer"
                v-text="
                  String(scale.time).length === 1
                    ? '0' + scale.time + ':00'
                    : scale.time + ':00'
                "
              ></span>
              <span v-if="scale.time !== undefined" class="scaleLine">|</span>
            </span>
            <span
              class="timeScale"
              :style="
                '---scaleWidth:' +
                ((rulerScale.length - 1) * 50 +
                  rulerScale[rulerScale.length - 1].scale) +
                'px'
              "
            ></span>
          </div>
          <div @mousedown="scrollVideoTime()" class="videoBlock">
            <div class="videoTail"></div>
          </div>
        </div>
      </div>
      <span
        class="smBtn"
        @click="selectData($event)"
        style="width: 77px; background-color: #6c757e"
      >
        <span
          style="position: absolute; width: 77px; height: 60px; cursor: pointer"
        >
          选择日期
        </span>
        <CSSelect id="datePicker">
          <el-date-picker
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            style="display: inline-block; width: 77px; height: 60px"
            :editable="false"
          ></el-date-picker>
        </CSSelect>
      </span>
      <span
        class="smBtn"
        @click="selectPlaySpeed($event)"
        style="width: 65px; background-color: #1dafff; position: relative"
      >
        <span>倍速×{{ videoControlData.videoSpeed }}</span>
        <div
          @click="changePlaySpeed($event)"
          v-show="visibleList.selectVideoSpeedVisible"
          style="
            position: absolute;
            width: 65px;
            height: 100px;
            bottom: 60px;
            background-color: lightblue;
            max-height: 100px;
            line-height: 24px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
          "
        >
          <span
            v-for="speed in videoSpeedList"
            :key="speed.id"
            :data-speed="speed.id"
            >×{{ speed.speed }}</span
          >
        </div>
      </span>
      <span
        class="smBtn"
        @click="downloadVideo()"
        style="
          border-radius: 0px 5px 5px 0px;
          width: 77px;
          background-color: #1ab394;
        "
        >下载录像</span
      >
    </div>
    <div
      :class="['top_list', { open: visibleList.topWarningVisible }]"
      :style="{
        '--max-top-list-height':
          (detectionConfigs.length + 1) * 40 > 400
            ? (detectionConfigs.length + 1) * 40 + 'px'
            : 400,
      }"
    >
      <div>
        <div>
          <div
            class="top_block"
            v-for="(str, index) in detectionConfigs"
            :key="index"
          >
            {{ str }}
          </div>
        </div>
      </div>
      <img
        @click="visibleList.topWarningVisible = !visibleList.topWarningVisible"
        v-if="visibleList.topWarningVisible"
        src="../../assets/icon/sh.png"
        alt=""
      />
      <img
        v-else
        src="../../assets/icon/xl.png"
        alt=""
        @click="visibleList.topWarningVisible = !visibleList.topWarningVisible"
      />
    </div>

    <div id="target-person-detail" v-show="targetPersonDetailVisible">
      <div class="header">查看个人档案</div>
      <div class="content">
        <div
          class="target_space"
          style="padding: 30px; border-bottom: 1px solid #f0f0f0; display: flex"
        >
          <img
            :src="targetPersonInfo.faceUrl"
            width="200px"
            height="200px"
            alt=""
          />
          <div class="targetInformation">
            <span>姓名：{{ targetPersonInfo.name || "-" }}</span>
            <span>身份：{{ identityType[targetPersonInfo.type] || "-" }}</span>
            <span>手机号：{{ targetPersonInfo.phone || "-" }}</span>
            <span>微信号：{{ targetPersonInfo.wx || "-" }}</span>
            <span
              >是否为关注人：{{
                isAttentionStr[targetPersonInfo.isAttention] || "-"
              }}</span
            >
            <span>所属企业：{{ targetPersonInfo.company || "-" }}</span>
            <span>所在房间：{{ targetPersonInfo.address || "-" }}</span>
          </div>
        </div>
        <div style="padding: 30px; position: relative">
          <div style="padding-bottom: 20px; line-height: 1">
            <div
              style="
                margin-right: 40px;
                vertical-align: middle;
                display: inline-block;
              "
            >
              行动轨迹
            </div>
            <div style="vertical-align: middle; display: inline-block">
              <span style="vertical-align: middle">显示近</span>
              <div
                style="
                  vertical-align: middle;
                  display: inline-block;
                  margin: 0 10px;
                "
              >
                <CSSelect iWidth="36px" height="40px">
                  <select
                    style="width: 100px"
                    v-model="dataTime"
                    @change="queryMovement()"
                  >
                    <!--                                    <option value="">天</option>-->
                    <option v-for="time in timeList" :key="time" :value="time">
                      {{ time }}天
                    </option>
                  </select>
                </CSSelect>
              </div>
              <span style="vertical-align: middle">数据</span>
            </div>
          </div>
          <div class="trail_detail" @scroll="loadActivityTrack">
            <div class="trail_content">
              <div
                class="trail"
                v-for="(action, index) in actionTrajectory"
                :key="action.id"
              >
                <p :class="['time', { active: index === 0 }]">
                  {{ action.date }}
                </p>
                <p>{{ action.cameraNo }} - {{ action.location }}</p>
                <div style="margin-top: 10px">
                  <img
                    :src="action.photoUrl"
                    @click="lookPicture(action.photoUrl)"
                    width="80px"
                    height="80px"
                    alt=""
                  />
                  <!--                                    <span
                                                                            class="allow-click"
                                                                            style="margin-left:20px"
                                                                            @click="checkVideo()"
                                                                        >
                                                                            录像
                                                                        </span>-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
          @click="targetPersonDetailVisible = false"
          class="btn btn-cancel"
          style="background: rgb(26, 179, 148); color: rgb(255, 255, 255)"
        >
          关闭
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
// import TargetPersonDetail from "@/components/SecurityCamera/targetPersonDetail";
import zkIcon from "../../../public/common/static/img/zk.png";
import ssIcon from "../../../public/common/static/img/ss.png";
import {
  getMonitorByConditionUrl,
  queryCameraCaptureAbnormalInfoUrl,
  queryCameraCaptureInfoUrl,
  queryCaptureNewFacePhotoUrl,
  queryEnableDetectionCountUrl,
  queryFileByIdUrl,
  queryMovementUrl,
  queryUntreatedCountUrl,
  setMonitorByConditionUrl,
  snapImgHost,
  enablePeopleGathering,
} from "@/requestUrl";

export default {
  name: "cameraControls",
  components: {
    CSSelect,
    // TargetPersonDetail,
  },
  data() {
    return {
      iSput: true,
      currentPage: "",
      snapImgHost,
      player: null,
      identityType: {
        1: "陌生人",
        2: "物业员工",
        3: "楼内用户",
        4: "访客",
      },
      isAttentionStr: {
        0: "不是",
        1: "VIP",
        2: "可疑人",
      },
      targetPersonDetailVisible: false,
      dataTime: 3,

      targetPersonInfo: {},
      subscribeId: "",
      actionTrajectory: [],
      isShowText: true,
      zkIcon,
      ssIcon,
      cameraId: "",
      abnormalType: {
        1: "人员聚集",
        2: "未带口罩",
        3: "吸烟",
        4: "跌倒",
        5: "像机被遮挡",
        6: "区域入侵",
        7: "车辆违停",
        8: "VIP",
        9: "可疑人",
      },
      dateTest: "",
      newestFaceList: [],
      abnormalFaceList: [],
      visibleList: {
        dialogVisible: false,
        newestFaceListVisible: false,
        leftCheckboxVisible: false,
        bottomAbnormalListVisible: false,
        underRulerVisible: false,
        topWarningVisible: true,
        selectVideoSpeedVisible: false,
        warningBoxVisible: false,
      },
      option: {
        play: true,
        currentVideo: true,
        showView: false,
        showAbnormalImg: false,
        showFaceImg: false,
        scale: 5,
        exitFullScreen: false,
        exitFullScreenTime: "",
        checkVIP: false,
        checkVIPTime: "",
        checkTrail: false,
        checkTrailTime: "",
        checkCar: false,
        checkCarTime: "",
        checkMask: false,
        checkMaskTime: "",
        checkSmoke: false,
        checkSmokeTime: "",
        checkFall: false,
        checkFallTime: "",
        checkBlock: false,
        checkBlockTime: "",
        checkCrowd: false,
        checkCrowdTime: "",
        crowdNum: "",
        checkWarning: false,
        warningDays: "",
      },
      // 设置检测项的配置
      detectionConfigOption: {
        1: { enable: false, val: "" },
        2: { enable: false, val: "" },
        3: { enable: false, val: "" },
        4: { enable: false, val: "" },
        5: { enable: false, val: "" },
        6: { enable: false, val: "" },
        7: { enable: false, val: "" },
        8: { enable: false, val: "", count: "" },
      },
      targetVideoTime: "23:59",
      currentVideoTime: {
        time: "00:00",
        second: "",
      },
      targetVideoInfo: {},
      rulerScale: [],
      detectionConfigs: [],
      timeList: [3, 7, 15, 30, 45, 60, 90],
      scrollPage: {
        UpDown: 0,
        LeftRight: 0,
      },
      testpicker: "",
      videoControlData: {
        videoDate: "",
        videoTime: "",
        videoSpeed: 1,
        videoUrl: "",
      },
      videoSpeedList: [
        { id: 1, speed: 16 },
        { id: 2, speed: 8 },
        { id: 3, speed: 4 },
        { id: 4, speed: 2 },
        { id: 5, speed: 1 },
        { id: 6, speed: 0.5 },
        { id: 7, speed: 0.25 },
      ],
      canvasPosition: {
        LT: "",
        RT: "",
        RB: "",
        LB: "",
      },
      untreatedCount: 0,
      scalesList: [],
    };
  },
  created() {
    this.$vc.on(this.$route.path, "cameraManage", "cameraControls", (data) => {
      this.currentPage = "";
      if (!data.visible) {
        this.exitFullScreen("eventBus");
        return;
      }
      if (data.target) {
        this.visibleList.dialogVisible = data.visible;
        this.visibleList.leftCheckboxVisible = data.visible;
        this.switchVisible("captureAbnormal");
        this.switchVisible("captureFace");
        this.el = data.el;
        const { id } = data.target;
        this.cameraId = id;
        this.newestFaceList = [];
        this.abnormalFaceList = [];
        this.detectionConfigs = [];
        this.scalesList = [];
        this.getCameraCaptureAbnormal();
        this.getCameraCaptureInfo(id);
        this.getCaptureNewPhoto();
        this.queryUntreatedCount(id);
        this.getDetectionConfig(id);
        this.getDetectionItemInfo(id);
      }
      this.targetVideoInfo = data.target;
    });
    this.jugeTime();
  },
  mounted() {
    var scales = document.querySelectorAll(".scaleText");
    for (var i = 0; i < scales.length - 1; i++) {
      this.scalesList.push({
        time: scales[i].innerText.slice(0, -1),
        position: parseInt(scales[i].style.cssText.split(":")[1].slice(0, -1)),
      });
    }

    this.$vc.on("cameraManageLeft", (res) => {
      setTimeout(() => {
        this.putCameraControls(res);
        this.putListScreen(res);
      });
      this.visibleList.topWarningVisible = false;
    });
  },
  methods: {
    lookPicture(picSrc) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", {
        title: "查看大图",
        photos: [picSrc],
        zIndex: 999999,
      });
    },
    /**
     * 查询关注人档案
     * @param {Number} id 关注人id
     * */
    queryRecord(id) {
      this.$fly
        .get(queryFileByIdUrl, {
          id,
        })
        .then((res) => {
          if (res.code === 0) this.targetPersonInfo = res.data;
        });
    },
    /**
     * 获取行动轨迹
     * @param {Number} lastId 最后一条数据的id
     * @param {Number} pageSize 分页
     * */
    queryMovement(lastId, pageSize = 5) {
      this.$fly
        .post(queryMovementUrl, {
          regionCode: this.$vc.getCurrentRegion().code,
          id: this.subscribeId,
          day: this.dataTime,
          leastId: lastId,
          total: pageSize,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          if (lastId) {
            this.actionTrajectory.push(...res.data);
          } else {
            this.actionTrajectory = res.data;
          }
        });
    },
    // 获取检测项设置
    getDetectionConfig(id) {
      this.$fly
        .get(getMonitorByConditionUrl, {
          id,
        })
        .then((res) => {
          if (res.code !== 0) {
            return;
          }
          console.log(res.data);
          let {
            isAttention,
            attentionDate,
            isPeopleCounting,
            peopleCountingDate,
            isIllegalParking,
            illegalParkingDate,
            isNoMask,
            noMaskDate,
            isSmoke,
            smokeDate,
            isFall,
            fallDate,
            isObstructView,
            obstructViewDate,
            isPeopleGathering,
            peopleGatheringDate,
            isRegionInvade,
            regionInvadeDate,
            peopleGatheringCount,
          } = res.data;

          this.detectionConfigOption = {
            1: { enable: !!isAttention, val: attentionDate },
            2: { enable: !!isPeopleCounting, val: peopleCountingDate },
            3: { enable: !!isIllegalParking, val: illegalParkingDate },
            4: { enable: !!isNoMask, val: noMaskDate },
            5: { enable: !!isSmoke, val: smokeDate },
            6: { enable: !!isFall, val: fallDate },
            7: { enable: !!isObstructView, val: obstructViewDate },
            8: {
              enable: !!isPeopleGathering,
              val: peopleGatheringDate,
              count: peopleGatheringCount,
            },
          };
        });
    },
    /**
     * 设置检测开关
     * @param {Number} type 检测类型   // 1关注人 2人流统计 3汽车违停 4口罩 5吸烟 6跌倒 7汽车遮挡
     * */
    setDetectionConfig(type) {
      console.log(this.detectionConfigOption);
      const { enable: isOpen, val: day } = this.detectionConfigOption[type];
      this.$fly.post(setMonitorByConditionUrl, {
        id: this.cameraId,
        isOpen,
        day,
        type,
      });
    },
    /**
     * 设置人员聚集检测开关
     * */
    setDetectionConfigGather(type) {
      const {
        enable: isOpen,
        val: day,
        count: count,
      } = this.detectionConfigOption[type];
      this.$fly.post(enablePeopleGathering, {
        id: this.cameraId,
        isPeopleGathering: isOpen ? 1 : 0,
        peopleGatheringDate: day,
        peopleGatheringCount: count,
      });
    },
    /**
     * 获取检测的配置
     * @param {Number} id 监控id
     * */
    getDetectionItemInfo(id) {
      this.$fly
        .get(queryEnableDetectionCountUrl, {
          id,
        })
        .then((res) => {
          console.log(res);
          //   this.visibleList.topWarningVisible =
          //     res.code === 0 && res.data.length > 0;
          console.log(res.data.length, res.code, "顶部提示框");
          const nameMapping = {
            89: "关注人（VIP/可疑人）",
            10: "人流统计",
            7: "汽车违停",
            2: "未戴口罩",
            3: "吸烟",
            4: "跌倒",
            // 5: '监控遮挡',
            1: "人员聚集",
            6: "区域入侵",
          };
          res.data.forEach((item) => {
            this.detectionConfigs.push(
              `近${item.day}天${nameMapping[item.name]}:${item.count}${
                item.name != 7 && item.name != 1 ? "人" : ""
              }次`
            );
          });
        });
    },
    // 获取最新抓拍的人脸
    getCaptureNewPhoto(pageNo = 1, pageSize = 20) {
      pageSize = Math.floor((window.innerHeight - 260 - 80 - 80) / 110) * 2;
      return this.$fly
        .get(queryCaptureNewFacePhotoUrl, {
          id: this.cameraId,
          pageNo,
          pageSize,
        })
        .then((res) => {
          this.newestFaceList.push(...(res.data.datas || []));
          return;
        });
    },
    queryUntreatedCount(id) {
      this.$fly
        .get(queryUntreatedCountUrl, {
          id,
        })
        .then((res) => (this.untreatedCount = res.data || 0));
    },
    /**
     * 获取异常抓拍
     * */
    getCameraCaptureAbnormal(pageNo = 1, pageSize = 20) {
      pageSize = Math.floor((window.innerWidth - 50 - 50) / 110) * 2;
      console.log(this.currentPage, pageNo);
      if (this.currentPage != pageNo) {
        this.currentPage = pageNo;
        return this.$fly
          .get(queryCameraCaptureAbnormalInfoUrl, {
            id: this.cameraId,
            pageNo,
            pageSize,
          })
          .then((res) => {
            console.log(res.data.datas);
            this.abnormalFaceList.push(...(res.data.datas || []));
            return;
          });
      } else {
        return;
      }
    },
    /**
     * 获取抓拍图片
     * @param {Number} id 监控id
     * */
    getCameraCaptureInfo(id) {
      this.$fly
        .get(queryCameraCaptureInfoUrl, {
          id,
        })
        .then((res) => {
          // this.newestFaceList = res.data.datas || [];
        });
    },
    async scroll(act) {
      if (act === "up" || act === "down") {
        var imgNumber = this.newestFaceList.length;
        var box = document.querySelector(".right_list .listContent");
        if (act === "up") {
          if (this.scrollPage.UpDown > 0) {
            this.scrollPage.UpDown--;
            box.style.transform =
              "translateY(" + this.scrollPage.UpDown * -110 + "px)";
          }
        } else if (act === "down") {
          if (imgNumber - this.scrollPage.UpDown <= 10) {
            await this.getCaptureNewPhoto(
              Math.floor(
                (imgNumber / ((window.innerHeight - 260 - 80 - 80) / 110)) * 2
              ) + 1
            );
          }

          if (this.scrollPage.UpDown < imgNumber - 10) {
            this.scrollPage.UpDown++;
            box.style.transform =
              "translateY(" + this.scrollPage.UpDown * -110 + "px)";
          }
        }
        return;
      }
      if (act === "left" || act === "right") {
        var imgNumber = this.abnormalFaceList.length;
        var box = document.querySelector(".bottom_list .listContent");
        if (act === "left") {
          if (this.scrollPage.LeftRight > 0) {
            this.scrollPage.LeftRight--;
            box.style.transform =
              "translateX(" + this.scrollPage.LeftRight * -130 + "px)";
          }
        } else if (act === "right") {
          if (imgNumber - this.scrollPage.LeftRight <= 10) {
            // this.currentPage = "";
            await this.getCameraCaptureAbnormal(
              Math.floor(
                imgNumber / (((window.innerWidth - 50 - 50) / 110) * 2)
              ) + 1
            );
          }
          if (this.scrollPage.LeftRight < imgNumber - 10) {
            this.scrollPage.LeftRight++;
            box.style.transform =
              "translateX(" + this.scrollPage.LeftRight * -130 + "px)";
          }
        }
      }
    },
    jugeTime() {
      var timeJuge = new RegExp(/^([0,1][0-9]{1}||2[0-3]{1}):[0-5][0-9]{1}$/);
      var time = this.targetVideoTime;
      if (timeJuge.test(time)) {
        var arr = time.split(":");
        var hours = arr[0];
        var minutes = arr[1];
        var scale = +((50 / 60) * minutes).toFixed(0);
        var i;
        for (i = 0; i <= hours; i++) {
          this.rulerScale.push({ id: i, time: i, type: "hours" });
        }
        this.rulerScale.push({ id: i + 1, scale, type: minutes });
      } else {
        this.$vc.toast("录像时间格式错误");
        this.visibleList.underRulerVisible = false;
      }
    },
    switchVisible(btn) {
      switch (btn) {
        case "playVideo":
          /*if (this.player.isPause) {
                        this.player.play();
                        this.player.isPause = true;
                    } else {
                        this.player.pause();
                        this.player.isPause = false;
                    }
                    this.option.play = !this.option.play;*/
          break;
        case "historyVideo":
          this.option.currentVideo = !this.option.currentVideo;
          break;
        case "hiddenIcon":
          this.option.showView = !this.option.showView;
          this.visibleList.underRulerVisible = this.option.showView;
          break;
        case "captureAbnormal":
          this.option.showAbnormalImg = !this.option.showAbnormalImg;
          this.visibleList.bottomAbnormalListVisible =
            this.option.showAbnormalImg;
          break;
        case "captureFace":
          this.option.showFaceImg = !this.option.showFaceImg;
          this.visibleList.newestFaceListVisible = this.option.showFaceImg;
          break;
      }
    },
    scale(act) {
      if (act === "close") {
        if (this.option.scale > 0) {
          this.option.scale--;
          this.$vc.toast("拉近" + this.option.scale + "倍");
        }
      } else if (act === "far") {
        if (this.option.scale < 10) {
          this.option.scale++;
          this.$vc.toast("放大" + this.option.scale + "倍");
        }
      }
    },
    /**
     * 退出全屏
     * @param {String} type 'eventBus' 监听到其它组件退出，不再触发退出全屏api   'click' 当前组件点击退出全屏
     * */
    exitFullScreen(type) {
      if (type !== "eventBus") {
        document.exitFullscreen();
      }
      this.option.showView = false;
      this.option.showAbnormalImg = false;
      this.option.showFaceImg = false;
      this.targetPersonDetailVisible = false;
      this.$vc.emit(this.$route.path, "viewImageModal", "close", null);
      for (let key in this.visibleList) {
        this.visibleList[key] = false;
      }
    },
    newestTargetTrail(id) {
      this.dataTime = 3;
      this.subscribeId = id;
      this.queryRecord(id);
      this.queryMovement();
      this.targetPersonDetailVisible = true;
    },
    WarningArea(btn) {
      switch (btn) {
        case "edit":
          this.visibleList.warningBoxVisible = true;
          break;
        case "save":
          this.visibleList.topWarningVisible = true;
          var canvas = document.querySelector(".canvas");
          if (this.visibleList.warningBoxVisible) {
            this.canvasPosition.LT = `(${canvas.offsetLeft},${canvas.offsetTop})`;
            this.canvasPosition.RT = `(${
              canvas.offsetLeft + canvas.offsetWidth
            },${canvas.offsetTop})`;
            this.canvasPosition.RB = `(${
              canvas.offsetLeft + canvas.offsetWidth
            },${canvas.offsetTop + canvas.offsetHeight})`;
            this.canvasPosition.LB = `(${canvas.offsetLeft},${
              canvas.offsetTop + canvas.offsetHeight
            })`;
            console.log(this.canvasPosition);
          } else {
            this.$vc.toast("请选择预警范围");
          }
          break;
        case "delete":
          this.visibleList.warningBoxVisible = false;
          break;
      }
    },
    selectData(event) {
      console.log("selectData", event.target);
    },
    selectPlaySpeed(event) {
      if (event.target.dataset.speed === undefined) {
        this.visibleList.selectVideoSpeedVisible =
          !this.visibleList.selectVideoSpeedVisible;
      }
    },
    changePlaySpeed(event) {
      if (event.target.dataset.speed != "") {
        this.videoSpeedList.forEach((item) => {
          if (+event.target.dataset.speed === item.id) {
            this.videoControlData.videoSpeed = item.speed;
          }
        });
      }
      this.visibleList.selectVideoSpeedVisible = false;
    },
    downloadVideo() {
      console.log("downloadVideo");
    },
    scrollVideoTime() {
      var nav = document.querySelector("#menu-nav");
      var rulerLength =
        parseInt(
          window.getComputedStyle(document.querySelector(".timeScale")).width
        ) - 15;
      var bigBox = document.querySelector(".time_ruler");
      var hiddenWidth = document.querySelector(".inBox").scrollLeft;
      var videoBlock = document.querySelector(".videoBlock");
      var videoTail = document.querySelector(".videoTail");
      window.onmousemove = (e) => {
        var position =
          e.clientX +
          -parseInt(window.getComputedStyle(nav).width) -
          bigBox.offsetLeft +
          hiddenWidth;
        if (position < 35) {
          position = 35;
        }
        if (position > rulerLength) {
          position = rulerLength;
        }
        var currentTime = (((position - 35) * 1.2) / 60).toFixed(2);
        var hour = currentTime.split(".")[0];
        var minute = ((+currentTime - hour) * 60).toFixed(0);
        var sumSecond = hour * 60 * 60 + minute * 60;
        if (hour.length === 1) {
          hour = "0" + hour;
        }
        if (minute.length === 1) {
          minute = "0" + minute;
        }
        this.currentVideoTime.time = hour + ":" + minute;
        this.currentVideoTime.second = sumSecond;
        console.log(this.currentVideoTime.time, this.currentVideoTime.second);
        videoBlock.style.marginLeft = position + "px";
        videoTail.style.width = 40 + position + "px";
        window.onmouseup = function () {
          window.onmousemove = null;
        };
      };
    },
    clearTime(act) {
      switch (act) {
        case "checkVIP":
          this.option.checkVIPTime = "";
          break;
        case "checkTrail":
          this.option.checkTrailTime = "";
          break;
        case "checkCar":
          this.option.checkCarTime = "";
          break;
        case "checkMask":
          this.option.checkMaskTime = "";
          break;
        case "checkSmoke":
          this.option.checkSmokeTime = "";
          break;
        case "checkFall":
          this.option.checkFallTime = "";
          break;
        case "checkBlock":
          this.option.checkBlockTime = "";
          break;
        case "checkCrowd":
          this.option.checkCrowdTime = "";
          break;
        case "checkWarning":
          this.option.warningDays = "";
          break;
      }
    },
    captureCursor(e) {
      function grabBoxDoubleSize(act) {
        var startWidth = parseInt(canvas.style.width);
        var startX = e.clientX;
        var startLeft = parseInt(canvas.style.left);
        var startHeight = parseInt(canvas.style.height);
        var startY = e.clientY;
        var startTop = parseInt(canvas.style.top);
        expandDirections(
          act,
          startWidth,
          startX,
          startLeft,
          startHeight,
          startY,
          startTop
        );
      }

      function grabBoxSize(act) {
        if (act === "left" || act === "right") {
          var startWidth = parseInt(canvas.style.width);
          var startX = e.clientX;
          var startLeft = parseInt(canvas.style.left);
          expandTheDirection(act, startWidth, startX, startLeft);
        } else if (act === "top" || act === "bottom") {
          var startHeight = parseInt(canvas.style.height);
          var startY = e.clientY;
          var startTop = parseInt(canvas.style.top);
          expandTheDirection(act, startHeight, startY, startTop);
        }
      }

      var canvas = document.querySelector(".canvas");
      if (e.offsetX < 10 && e.offsetY < 10) {
        canvas.style.cursor = "nw-resize";
        window.onmousedown = function (e) {
          grabBoxDoubleSize("LT");
        };
      } else if (
        e.offsetY > e.target.offsetHeight - 10 &&
        e.offsetX > e.target.offsetWidth - 10
      ) {
        canvas.style.cursor = "nw-resize";
        window.onmousedown = function () {
          grabBoxDoubleSize("RB");
        };
      } else if (e.offsetY < 10 && e.offsetX > e.target.offsetWidth - 10) {
        canvas.style.cursor = "ne-resize";
        window.onmousedown = function () {
          grabBoxDoubleSize("RT");
        };
      } else if (e.offsetX < 10 && e.offsetY > e.target.offsetHeight - 10) {
        canvas.style.cursor = "ne-resize";
        window.onmousedown = function () {
          grabBoxDoubleSize("LB");
        };
      } else if (e.offsetX < 10) {
        canvas.style.cursor = "w-resize";
        window.onmousedown = function () {
          grabBoxSize("left");
        };
      } else if (e.offsetX > e.target.offsetWidth - 10) {
        canvas.style.cursor = "w-resize";
        window.onmousedown = function () {
          grabBoxSize("right");
        };
      } else if (e.offsetY < 10) {
        canvas.style.cursor = "n-resize";
        window.onmousedown = function () {
          grabBoxSize("top");
        };
      } else if (e.offsetY > e.target.offsetHeight - 10) {
        canvas.style.cursor = "n-resize";
        window.onmousedown = function () {
          grabBoxSize("bottom");
        };
      } else {
        this.visibleList.expandCaptureVisible = false;
        canvas.style.cursor = "grab";
        window.onmousedown = null;
      }

      function expandTheDirection(act, boxSize, offset, position) {
        if (act === "left") {
          window.onmousemove = function (e) {
            var expandWidth = offset - e.clientX;
            var width = boxSize + expandWidth;
            var left = position - expandWidth;
            if (width < 400) {
              width = 400;
              left = position + boxSize - 400;
            }
            canvas.style.width = width + "px";
            canvas.style.left = left + "px";
          };
        } else if (act === "right") {
          window.onmousemove = function (e) {
            var expandWidth = e.clientX - offset;
            var width = boxSize + expandWidth;
            if (width < 400) {
              width = 400;
            }
            canvas.style.width = width + "px";
          };
        } else if (act === "top") {
          window.onmousemove = function (e) {
            var expandHeight = offset - e.clientY;
            var height = boxSize + expandHeight;
            var top = position - expandHeight;
            if (height < 300) {
              height = 300;
              top = position + boxSize - 300;
            }
            canvas.style.height = height + "px";
            canvas.style.top = top + "px";
          };
        } else if (act === "bottom") {
          window.onmousemove = function (e) {
            var expandHeight = e.clientY - offset;
            var height = boxSize + expandHeight;
            if (height < 300) {
              height = 300;
            }
            canvas.style.height = height + "px";
          };
        }
      }

      function expandDirections(act, w, x, l, h, y, t) {
        if (act === "LT") {
          window.onmousemove = function (e) {
            var expandWidth = x - e.clientX;
            var width = w + expandWidth;
            var left = l - expandWidth;
            if (width < 400) {
              width = 400;
              left = l + w - 400;
            }
            var expandHeight = y - e.clientY;
            var height = h + expandHeight;
            var top = t - expandHeight;
            if (height < 300) {
              height = 300;
              top = t + h - 300;
            }
            canvas.style.height = height + "px";
            canvas.style.width = width + "px";
            canvas.style.left = left + "px";
            canvas.style.top = top + "px";
          };
        } else if (act === "RT") {
          window.onmousemove = function (e) {
            var expandWidth = e.clientX - x;
            var width = w + expandWidth;
            if (width < 400) {
              width = 400;
            }
            var expandHeight = y - e.clientY;
            var height = h + expandHeight;
            var top = t - expandHeight;
            if (height < 300) {
              height = 300;
              top = t + h - 300;
            }
            canvas.style.width = width + "px";
            canvas.style.height = height + "px";
            canvas.style.top = top + "px";
          };
        } else if (act === "LB") {
          window.onmousemove = function (e) {
            var expandWidth = x - e.clientX;
            var width = w + expandWidth;
            var left = l - expandWidth;
            if (width < 400) {
              width = 400;
              left = l + w - 400;
            }
            var expandHeight = e.clientY - y;
            var height = h + expandHeight;
            if (height < 300) {
              height = 300;
            }
            canvas.style.width = width + "px";
            canvas.style.left = left + "px";
            canvas.style.height = height + "px";
          };
        } else if (act === "RB") {
          window.onmousemove = function (e) {
            var expandWidth = e.clientX - x;
            var width = w + expandWidth;
            if (width < 400) {
              width = 400;
            }
            canvas.style.width = width + "px";
            var expandHeight = e.clientY - y;
            var height = h + expandHeight;
            if (height < 300) {
              height = 300;
            }
            canvas.style.height = height + "px";
          };
        }
      }
    },
    moveCaptureBox(e) {
      var leftDistance, topDistance;
      var startX = e.offsetX;
      var startY = e.offsetY;
      var bigBox = document.querySelector("body");
      var canvas = document.querySelector(".canvas");
      var maxWidth = bigBox.clientWidth - e.target.offsetWidth;
      var maxHeight = bigBox.clientHeight - e.target.offsetHeight;

      if (canvas.style.cursor === "grab") {
        window.onmousemove = function (e) {
          leftDistance = e.clientX - startX;
          topDistance = e.clientY - startY;
          if (leftDistance < 0) {
            leftDistance = 0;
          }
          if (topDistance < 0) {
            topDistance = 0;
          }
          if (leftDistance > maxWidth) {
            leftDistance = maxWidth;
          }
          if (topDistance > maxHeight) {
            topDistance = maxHeight;
          }
          canvas.style.left = leftDistance + "px";
          canvas.style.top = topDistance + "px";
        };
        window.onmouseup = function () {
          window.onmousemove = null;
        };
      }
    },
    turnToTheTime(event) {
      if (event.target.className === "scaleTxt") {
        var videoBlock = document.querySelector(".videoBlock");
        var videoTail = document.querySelector(".videoTail");
        var coordinate = parseInt(
          event.target.parentNode.style.cssText.split(":")[1].slice(0, -1)
        );
        videoBlock.style.marginLeft = coordinate + 35 + "px";
        videoTail.style.width = 40 + coordinate + "px";
        var sumSecond = +event.target.innerText.split(":")[0] * 60 * 60;
        this.currentVideoTime.time = event.target.innerText;
        this.currentVideoTime.second = sumSecond;
        console.log(this.currentVideoTime.time, this.currentVideoTime.second);
      }
    },
    //展开收起listScreen
    putListScreen(isSput) {
      if (isSput) {
        this.$refs.putListScreen.style.bottom = `-${
          document.querySelector(".bottom_list").clientHeight
        }px`;
        // this.$refs.putListScreen.style.transition = "1s";
      } else {
        this.$refs.putListScreen.style.bottom = "0px";
      }
      console.log(isSput);
      this.iSput = !isSput;
      console.log(this.iSput);
    },
    //收起、展开CameraControls
    putCameraControls(isShowText) {
      if (isShowText) {
        this.$refs.leftCheckboxVisible.style.left = `-${
          document.querySelector("#cameraControls .left_list").clientWidth
        }px`;
        this.$refs.leftCheckboxVisible.style.transition = "1s";
        this.isShowText = !isShowText;
      } else {
        this.$refs.leftCheckboxVisible.style.left = "0px";
        this.isShowText = !isShowText;
      }
      console.log(isShowText);
    },
    // 加载活动轨迹
    loadActivityTrack(e) {
      const MIN_TRAIL_HEIGHT = 180, // 一条行动轨迹的最低高度
        { firstChild, clientHeight, scrollTop } = e.target;
      if (this.loadTimer) {
        clearTimeout(this.loadTimer);
        this.loadTimer = null;
      }
      this.loadTimer = setTimeout(() => {
        if (
          firstChild.clientHeight - clientHeight - scrollTop <
          MIN_TRAIL_HEIGHT
        ) {
          this.queryMovement(
            this.actionTrajectory[this.actionTrajectory.length - 1].id
          );
        }
      }, 200);
    },
  },
};
</script>

<style scoped>
* {
  user-select: none;
}

#cameraControls .right_list {
  width: 120px;
  position: fixed;
  right: 30px;
  top: 80px;
  height: calc(100vh - 260px - 80px);
  bottom: 260px;
  margin: auto;
  z-index: 2005;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

#cameraControls .right_list .listScreen {
  margin: 40px 15px;
  height: calc(100% - 80px);
  overflow: hidden;
}
.listScreens {
  /* position: absolute;
  bottom:0; */
}
#cameraControls .bottom_list {
  width: calc(100vw - 100px);
  height: 180px;
  position: fixed;
  bottom: -180px;
  left: 50px;
  right: 50px;
  /*transform: translateX(-50%);
    margin: auto;*/
  z-index: 2005;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  transition: all 1s;
}

#cameraControls .bottom_list .listContent {
  display: flex;
  height: 150px;
}

#cameraControls .bottom_list .listContent .abnormalSpanText {
  width: 110px;
  height: 40px;
  /* color:#FF5A5A; */
  font-size: 20px;
  /* background-color:#FFE6E6; */
  display: inline-block;
  text-align: center;
  line-height: 40px;
  border-radius: 20px;
}

#cameraControls .bottom_list .block {
  width: 110px;
  margin: 0px 10px;
}

#cameraControls .left_list {
  position: fixed;
  left: -490px;
  top: 45%;
  font-size: 20px;
  z-index: 2005;
  transform: translateY(-50%);
  /*box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.15);*/
  border-radius: 0 5px 5px 0;
}

#cameraControls .left_list .layout {
  background: rgba(255, 255, 255, 0.5);
  overflow-y: auto;
  /*height: 740px;*/
  color: #000;
  width: 486px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#cameraControls .left_list .layout .checkButton {
  padding: 0 15px;
  display: inline-block;
  margin-bottom: 20px;
}

#cameraControls .left_list .layout .checkBox {
  margin: 12px 0px;
  padding-left: 35px;
}

#cameraControls .left_list .layout .inputBox {
  margin: 12px 15px;
}

#cameraControls .left_list .layout .inputBox {
  margin-bottom: 0;
}

#cameraControls .left_list .layout .blankBox {
  margin-bottom: 12px;
}

#cameraControls .left_list .layout select {
  color: #000;
}

#cameraControls .left_list .layout .checkButton button {
  width: 180px;
  height: 40px;
  border-radius: 6px;
  font-size: 20px;
  line-height: 26px;
  padding: 6px 8px;
}

#cameraControls .icon_arrow {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #1ab394;
  box-shadow: 0px 0px 10px 0px #1ab394;
}

#cameraControls .time_ruler {
  position: absolute;
  bottom: 230px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1230px;
  height: 60px;
  background-color: #fff;
  border-radius: 5px;
  color: #000000;
}

#cameraControls .time_ruler .smBtn {
  display: inline-block;
  text-align: center;
  line-height: 60px;
  color: #fff;
  cursor: pointer;
}

#cameraControls .time_ruler .smBtn #datePicker {
  width: 77px;
  height: 60px;
  cursor: pointer;
  display: inline-block;
  vertical-align: top;
  border: 0px;
  opacity: 0;
  position: relative;
}

#cameraControls .timeScale {
  width: var(---scaleWidth, 1200px);
  position: absolute;
  bottom: 0px;
  height: 1px;
  background-color: #000;
}

#cameraControls .scaleText {
  position: absolute;
  top: 10px;
  left: var(---textLeft, 0px);
  margin-left: 23px;
}

#cameraControls .scaleLine {
  font-size: 100px;
  transform: scale(0.1);
  position: absolute;
  left: 4px;
  bottom: -80px;
}
.bottoms {
  transform: rotateZ(180deg);
  position: absolute;
  left: calc(50% - 100px);
  top: -35px;
  z-index: -10;
}

#cameraControls .videoBlock {
  width: 12px;
  height: 45px;
  position: absolute;
  background-color: #1ab394;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  margin-left: 35px;
  border-radius: 7px;
  cursor: pointer;
}

#cameraControls .videoTail {
  position: absolute;
  right: 8px;
  width: 40px;
  height: 39px;
  background-color: #1ab394cc;
  z-index: 0;
  cursor: default;
}

#cameraControls .top_list {
  width: 360px;
  padding: 0 20px;
  position: fixed;
  top: 0;
  left: 700px;
  z-index: 2005;
  color: #000;
  transform: translateX(-50%);
  background: rgba(255, 255, 255, 0.5);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  box-sizing: border-box;
}

#cameraControls .top_list > div {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  transition: all 1s cubic-bezier(0.1, 1.1, 0.58, 1) 0s;
}

#cameraControls .top_list > div > div {
  overflow-y: auto;
}

#cameraControls .top_list.open > div {
  max-height: var(--max-top-list-height, 80px);
  padding: 5px 0 10px 0;
}

#cameraControls .top_list img {
  position: absolute;
  left: 50%;
  width: 280px;
  height: 37px;
  bottom: -37px;
  transform: translateX(-50%);
  cursor: pointer;
}

#cameraControls .top_list .top_block {
  font-size: 20px;
}

#cameraControls .top_list .top_block:not(:last-of-type) {
  border-bottom: 1px solid #f0f0f0;
}

.put {
  position: absolute;
  right: -34px;
  top: 50%;
  width: 34px;
  height: 186px;
  cursor: pointer;
  transform: translateY(-50%);
}

.enter-active {
  left: -400px;
}

.leave-active {
  left: 0px;
}
#target-person-detail {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2005;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
  transform: translate(-50%, -50%);
}

#target-person-detail .content {
  max-height: 969px;
  font-size: 24px;
  color: #000;
}

#target-person-detail .header {
  padding: 30px;
  font-size: 24px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  position: relative;
  background: #fff;
  color: #1ab394;
  border-bottom: 1px solid #f0f0f0;
}

#target-person-detail .footer {
  padding: 20px;
  position: relative;
  text-align: center;
  border-top: 1px solid #f0f0f0;
}

#target-person-detail .footer .btn {
  height: 40px;
  border-radius: 6px;
  font-size: 24px;
  line-height: 30px;
  padding: 0 10px;
}

#target-person-detail .targetInformation {
  display: inline-block;
  margin-left: 30px;
  width: 730px;
  height: 200px;
}

#target-person-detail .targetInformation span {
  display: inline-block;
  width: 340px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 4px 0;
  margin-right: 40px;
}

#target-person-detail .targetInformation span:nth-of-type(even) {
  margin-right: 0;
}
/* #targetPersonDetail .trail_detail .trail_nav .point{
    display:inline-block;
    width:16px;
    height:16px;
    border-radius:50%;
    background-color:#1ab394;
} */
/* #targetPersonDetail .trail_detail .trail_nav .line{
    display:inline-block;
    width:2px;
    height:var(---line-height , 170px);
    background-color:#999999;
} */

.trail_detail {
  height: 400px;
  overflow-y: auto;
}

.trail {
  padding-left: 30px;
  position: relative;
}

.trail .time {
  position: relative;
  line-height: 1;
}

.trail .time::before {
  position: absolute;
  left: -30px;
  content: " ";
  top: 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #999999;
}

.trail .time.active::before {
  background: #00b694;
}

.trail:not(:last-of-type) {
  padding-bottom: 20px;
}
.trail:not(:last-of-type)::before {
  position: absolute;
  content: " ";
  top: 16px;
  left: 8px;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background: #999;
}

@media screen and (max-width: 1400px) {
  #target-person-detail .content {
    max-height: 610px;
  }

  #target-person-detail {
    transform: scale(0.8) translate(calc(-50% / 0.8), calc(-50% / 0.8));
  }
}
</style>
